import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Pagination, Row, Col } from 'react-bootstrap'
class Pages extends Component {

    pageItems = () => {
        const { page, countPages } = this.props
        let items = []
        const ellipsisLeft = page - 2 > 1
        const ellipsisRight = page + 2 < countPages
        if (ellipsisLeft) items = [...items, <Pagination.Ellipsis key="ellipsisLeft" />]

        for (let i = (ellipsisLeft ? page - 2 : 1); i <= (ellipsisRight ? page + 2 : countPages); i++) {
            items = [
                ...items,
                <Pagination.Item
                    key={i}
                    onClick={() => this.props.handlePageChange(i)}
                    active={page === i}>
                    {i}
                </Pagination.Item>
            ]
        }

        if (ellipsisRight) items = [...items, <Pagination.Ellipsis key="ellipsisRight" />]
        return items
    }
    pageNavigation = () => {
        const { page, countPages } = this.props
        return (
            <>
                <Pagination.Prev

                    onClick={() => this.props.handlePageChange(page > 1 ? page - 1 : 1)}
                    disabled={page === 1}
                >
                    Prev
                </Pagination.Prev>

                <Pagination.Next

                    style={{ marginLeft: "10px" }}
                    onClick={() => this.props.handlePageChange(page < countPages ? page + 1 : countPages)}
                    disabled={page === countPages}
                >
                    Next
                </Pagination.Next>
            </>
        )
    }

    render() {
        const { countPages, } = this.props
        if (countPages === 0) {
            return null
        }
        const userRole = this.props.user.role
        return (
            <Row style={{ float: "right" }}>
                {/* <Col style={{ margin: "auto" }}>Page {page}/{countPages}</Col> */}
                <Col >
                    <Pagination style={{ margin: "auto" }} >
                        {userRole === "admin" && <Pagination.First onClick={() => this.props.handlePageChange(1)} />}
                        {userRole === "admin" ? this.pageItems() : this.pageNavigation()}
                        {userRole === "admin" && <Pagination.Last onClick={() => this.props.handlePageChange(countPages)} />}
                    </Pagination>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.authenticate.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Pages)

import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loading from "../../../layouts/Loading/Loading";
import API from '../../../utils/API/API';
import { ErrorMessageToast } from "../../ErrorSuccess/ErrorMessageToast";
export const NotificationContainer = (props) => {
    const { business } = props
    const [createdNotification, setCreatedNotification] = useState(null)
    const [notification, setNotification] = useState({
        business: business._id,
        title: '',
        message: '',
        links: [{
            url: ''
        }],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let links = notification.links
        if (name === "link") {
            links = [{
                url: value
            }]
            setNotification((prevNotification) => ({
                ...prevNotification,
                links,
            }));
        } else {
            setNotification((prevNotification) => ({
                ...prevNotification,
                [name]: value,
            }));
        }

    };

    const deleteNotification = (_id) => {
        setMessage(null)
        setError(null)
        setLoading(true)
        API.delete(`/api/notification/delete-notification?_id=${_id}`).then(res => {
            if (res) {
                setLoading(false)
                setMessage("Notification was deleted successfully!")
                setCreatedNotification(null)
            }
        }).catch(error => {
            let errorMessage = "Error when deleting the notification!!"
            if (error.message) {
                errorMessage = error.message
            }
            setLoading(false)
            setError(errorMessage)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(null)
        setError(null)
        setLoading(true)
        API.post("/api/notification/create-notification", notification).then(res => {
            if (res.data) {
                setLoading(false)
                setMessage("The notification was created successfully!")
                setCreatedNotification(res.data.createdNotification)
                setNotification({
                    business: business._id,
                    title: '',
                    message: '',
                    links: [{
                        url: ''
                    }],
                })
            }
        }).catch(error => {
            let errorMessage = "There is an error creating the notification!"
            if (error.message) {
                errorMessage = error.message
            }
            setLoading(false)
            setError(errorMessage)
        })
        // Add your submission logic here
    };
    console.log(error, message)

    return (
        <div>
            <h4>Create Notification</h4>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                placeholder='Title'
                                name='title'
                                value={notification.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                placeholder='Link'
                                name='link'
                                value={notification.links[0].url}
                                onChange={(e) => handleChange(e, 'link')}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder='Message'
                                name='message'
                                value={notification.message}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-3'>
                        {!loading ? (
                            <Button
                                variant='primary'
                                className='float-left'
                                type='submit'
                            >
                                <span
                                    className='oi oi-bell'
                                    title='icon name'
                                    aria-hidden='true'
                                /> Send
                            </Button>
                        ) : (
                            <Loading />
                        )}
                    </Col>
                </Row>
            </Form>
            {createdNotification && <div><Row className="mt-3">
                <Col>
                    <h5>{createdNotification.title}</h5>
                </Col>
                <Col>
                    <p>{createdNotification.links[0].url}</p>
                </Col>
            </Row>
                <Row>
                    <Col>
                        <p>{createdNotification.message}</p>
                        <Button variant='danger' onClick={() => deleteNotification(createdNotification._id)}>Unsend</Button>
                    </Col>
                </Row>
            </div>}

            <h4 className="mt-3">Template Notifications</h4>
            <Row className="mt-1">
                <Col>
                    <Button variant='secondary' onClick={() => setNotification({
                        business: business._id,
                        title: 'Your Online Gift Card is Ready! 🎁',
                        message: 'Your online gift card feature is now ready to use! You can start accepting online payments for gift cards, making it easier for your customers to purchase and share your services. To get started, go to Settings -> Online Gift Card to enable it.',
                        links: [{
                            url: 'https://www.partnersdash.com/settings/gift-cards'
                        }],
                    })}>
                        Online Gift Card is ready
                    </Button>

                </Col>
                <Col>
                    <Button variant='secondary' onClick={() => setNotification({
                        business: business._id,
                        title: 'Failed Payment Notification ⚠️',
                        message: 'We noticed that the payment for your account last month was unsuccessful. Please check your credit card balance or update your payment method to ensure uninterrupted service. The total amount due is $---. Thank you',
                        links: [{
                            url: 'https://www.partnersdash.com/reports/billing'
                        }],
                    })}>
                        Failed Payment Notification
                    </Button>

                </Col>
                <Col>
                    <Button variant='secondary' onClick={() => setNotification({
                        business: business._id,
                        title: 'Renew Domain Name Notification 🌐',
                        message: 'Your domain name, hosting service and SSL certificate for your website at: ------ will be automatically renewed in 7 days. Please ensure that your payment method is up to date to avoid any service interruptions. The total amount due is $180 + applicable taxes. If you would like to cancel your website, please let us know by email: merchants@dashbooking.com. Thank you',
                        links: [{
                            url: 'https://www.partnersdash.com/reports/billing'
                        }],
                    })}>
                        Renew Domain Name Notification
                    </Button>

                </Col>

            </Row>
            <ErrorMessageToast error={error} message={message} />
        </div>
    );
};

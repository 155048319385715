import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
// import CreateNewBusinessModal from "../../components/business/CreateNewBusinessModal";
import { getBusinessType } from "../../redux/actions/admin/business-type";
import Loading from "../../layouts/Loading/Loading";
import BusinessSearchForm from "../../components/business-search/BusinessSearchForm";
import { getBusinessStatus } from "../../redux/actions/admin/business-status";
import { getAllProvincesCities } from "../../redux/actions/province-city/province-city";
import BusinessSearchResultTable from "../../components/business-search/BusinessSearchResultTable";
import Pages from "../../components/common/page/Pages";
import { searchBusiness } from "../../redux/actions/business/business-search";

class BusinessContainer extends Component {
    state = {
        // modalShow: false,
        // setModalShow: false,
        displaySearchResult: false,
        page: 1,
    };

    displaySearchResult = () => {
        this.setState({
            displaySearchResult: true,
        });
    };

    hideSearchResult = () => {
        this.setState({
            displaySearchResult: false,
        });
    };

    handlePageChange = (page) => {
        this.setState({ page }, () => {
            this.props.searchBusiness(
                this.props.location.search + `&page=${page}`
            );
            this.displaySearchResult();
        });
    };

    componentDidMount() {
        this.props.getBusinessType();
        this.props.getAllProvincesCities();
        this.props.getBusinessStatus();
    }

    // openCloseAddModal = e => {
    //     this.setState({
    //         modalShow: !this.state.modalShow
    //     });
    // };

    render() {
        const { displaySearchResult, page } = this.state;
        const { businessType, provinceCity, businessStatus, countbusinesses } =
            this.props;

        if (
            businessType.loading ||
            provinceCity.loading ||
            businessStatus.loading
        ) {
            return <Loading />;
        }

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Business</h1>
                        <Button
                            className='btn btn-info text-white mb-3'
                            onClick={() =>
                                this.props.history.push("/create-business")
                            }
                        >
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>

                <BusinessSearchForm
                    history={this.props.history}
                    hideSearchResult={this.hideSearchResult}
                    displaySearchResult={this.displaySearchResult}
                    location={this.props.location}
                    cities={provinceCity.cities}
                    provinces={provinceCity.provinces}
                    statuses={businessStatus.businessStatuses}
                    businessTypes={businessType.businessTypes}
                />

                {displaySearchResult && (
                    <div>
                        {/* {countbusinesses > 0 && <h3>Total: {countbusinesses}</h3>} */}
                        <BusinessSearchResultTable
                            history={this.props.history}
                        />
                        {countbusinesses > 0 && (
                            <Row style={{ padding: "10px 0px 30px 0px" }}>
                                <Col>
                                    <Pages
                                        page={page}
                                        countPages={Math.ceil(
                                            countbusinesses / 10
                                        )}
                                        handlePageChange={this.handlePageChange}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
                {/* <CreateNewBusinessModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    businessTypes = {businessType.businessTypes}
                /> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    businessType: {
        businessTypes: state.businessType.businessTypes,
        loading: state.businessType.loading,
        error: state.businessType.error,
        message: state.businessType.message,
    },
    provinceCity: {
        cities: state.provinceCity.cities,
        provinces: state.provinceCity.provinces,
        loading: state.provinceCity.loading,
        error: state.provinceCity.error,
        message: state.provinceCity.message,
    },
    businessStatus: {
        businessStatuses: state.businessStatus.businessStatuses,
        provinces: state.businessStatus.provinces,
        loading: state.businessStatus.loading,
        error: state.businessStatus.error,
        message: state.businessStatus.message,
    },
    business: {
        businesses: state.business.businesses,
        business: state.business.business,
        loading: state.business.loading,
        error: state.business.error,
        message: state.business.message,
    },

    businesses: state.searchBusiness.businesses,
    searchLoading: state.searchBusiness.loading,
    countbusinesses: state.searchBusiness.countbusinesses,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessType: () => dispatch(getBusinessType()),
        getBusinessStatus: () => dispatch(getBusinessStatus()),
        getAllProvincesCities: () => dispatch(getAllProvincesCities()),
        searchBusiness: (query) => dispatch(searchBusiness(query)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessContainer);
